@use "sass:math";

.sightseeing__review {
	@include create-main-wrapper;
	@include create-row-card;
	padding-left: .625rem;

	&_main {
		padding-top: $base-padding*0.5;

		.content {
			padding-left: $base-padding*0.5;
			padding-right: 0;
		}
	}

	&_header {
		.header__container {
			min-height: 28px;
			background-color: $sightseeing-contact-header-bg-color;
			color: $sightseeing-contact-header-text-color;
			text-transform: uppercase;
			padding: 5px;
			font-size: 0.75rem;
			font-weight: 600;
			@include clearfix();

			width: 100%;

			&_heading {
				@include make-one-third;
				padding-left: 0.625rem;
				text-align: left;
			}

			&_guest-heading {
				@include make-one-sixth;
				text-align: left;
			}
		}

		.header__details-container {
			min-height: 30px;
			background-color: $sightseeing-contact-header-bg-color;
			color: $sightseeing-contact-header-text-color;
			text-transform: uppercase;
			padding: 5px;
			font-size: 0.75rem;
			font-weight: 600;
			width: 100%;
			@include clearfix();

			&_heading {
				@include make-one-fifth;
				padding-left: .625rem;
				text-align: left;
			}

		}
	}

	&_card {
		box-shadow: $row-shadow-color;
		padding: 0 .9375rem;
		padding-bottom: 1rem;

		&_header {
			text-transform: uppercase;
			color: $brand-color-2;
			padding-left: 0px;
			content: '';
			display: table;
			width: 100%;
		}

		.header_text {
			margin-right: $base-margin*0.125;
			font-size: $font-size-h5;
		}

		.guest_occupancy {
			float: left;
		}

		.vat_title {
			float: right;
		}

		.inclusive_vat {
			padding-right: 6px;
			color: red;
			font-size: 85%;
		}

		&_content {

			padding-bottom: .9375rem;
			padding-top: .9375rem;
		}

		@include create-review-nav;

		&-overview,
		&-inclusions,
		&-remarks,
		&-cancellation-policy {
			padding-bottom: 0.9375rem;
			padding-top: 0.9375rem;
		}

		&-guest {
			padding-bottom: 0.9375rem;
		}

		&_questions-required-tab {
			position: relative;

			>a {
				&:after {
					content: "\f069";
					font-family: FontAwesome;
					position: absolute;
					top: 0.625rem;
					font-size: 0.4rem;
					color: $brand-color-37;
					padding-left: 0.125rem;
					padding-bottom: 0.625rem;
				}
			}
		}

		&-image {
			@include clearfix();
			float: left;
			min-height: 1px;
			width: 25%;
			>img {
					width: 100%;
					height: 150px;
					padding: .625rem;
				}
		}
	}

	&_row {
		@include clearfix;
		padding-bottom: 0.9375rem;
		padding-top: 0.9375rem;

		.row {
			&__main-details {
				padding-top: 0 .9375rem;
				text-align: left;

				&_heading {
					@include make-one-third;
					color: $brand-color-2;
					font-size: $font-size-h4;
				}

				&_data {
					@include make-one-third;
					color: $brand-color-2;
					font-size: $font-size-h4;
					padding-left: .625rem;
				}

				&_option-data {
					width: 100%;
					color: $brand-color-2;
					font-size: $font-size-h4;
					padding: $base-padding*0.5;
					display: flex;
					padding-bottom: 0;
				}
			}

			&__other-details {
				@include make-one-third;
				text-align: left;

			}

			&__details {
				@include make-one-fifth;
				text-align: left;
			}

			&__remarks {
				text-align: left;
				padding-left: 0.9375rem;
				padding-right: 0.9375rem;

			}

			&__guest-occupancy {
				@include make-one-sixth;
				text-align: left;


			}

		}
	}

	&_marhaba-detail {
		float: left;
		width: 75%;
		min-height: 5.625rem;
	}

	&_marhaba-detail-name {
		@include clearfix();
		max-height: 6.25rem;
		text-align: left;
		font-family: Open Sans;
		font-size: 1rem;
		font-weight: 400;
		padding-bottom: 1%;
		padding-top: 1%;
		text-transform: capitalize;
		color: $brand-color-2;
	}

	&_marhaba-description {
		padding-bottom: 0.625rem;
		width: 100%;
	}

	&_marhaba {
		@include make-one-fifth;
		padding-right: math.div($base-padding, 3);
		padding-top: $base-padding;
		padding-bottom: math.div($base-padding, 3);

		.review {
			&__insurance {
				>img {
					width: 100%;
				}
			}
		}
	}
}

.auxilary-details {
	@include create-fare-details-extra-content(0%, 0%);
}

.essential-notes {
	&__header {
		padding-top: $base-padding*0.5;
		display: inline-block;
		color: $brand-color-2;
	}

	&__content {
		text-align: justify;
		padding: $base-padding*0.5;
	}
}

.sightseeing-header {
	min-height: 28px;
	background-color: $sightseeing-contact-header-bg-color;
	color: $sightseeing-contact-header-text-color;
	text-transform: uppercase;
	padding: 5px;
	padding-left: 0;
	font-size: 0.75rem;
	font-weight: 600;
	width: 100%;
	@include clearfix();

	&__sightseeing-name,
	&__city,
	&__date,
	&__title,
	&__remark-title,
	&__inclusion-title,
	&__exclusion-title,
	&__notes-title {
		@include make-one-third;
		padding-left: 0.625rem;
		text-align: left;
		width: 25%;
	}

	&__sightseeing-name {
		width: 50%;
	}

	&__remark-title {
		width: 100%;
	}

	&__inclusion-title,
	&__exclusion-title,
	&__notes-title {
		width: 33.33%;
	}
}

.sightseeing-review-details {
	font-size: 0.75rem !important;
	@include clearfix();
	padding-bottom: 0.3125rem;
	padding-top: 0.3125rem;

	&__sightseeing-name,
	&__city,
	&__date,
	&__value,
	&__remark-value,
	&__inclusion-value,
	&__exclusion-value,
	&__notes-value {
		@include make-one-third;
		font-size:  0.75rem;
		text-align: left;
		width: 25%;
		padding-left: 0.625rem;
	}

	&__sightseeing-name,
	&__city,
	&__date {
		color: $brand-color-2;
	}

	&__sightseeing-name {
		width: 50%;
	}

	&__remark-value {
		width: 100%;
	}

	&__inclusion-value,
	&__exclusion-value,
	&__notes-value {
		width: 33.33%;
	}
}

.traveller-details-container,.selected-sightseeing-details-container {
	width: 100%;
	@include clearfix();

	&__selected-data {
		float: left;
		width: 20%;

		&_title {
			min-height: 28px;
			background-color: $sightseeing-contact-header-bg-color;
			color: $sightseeing-contact-header-text-color;
			text-transform: uppercase;
			padding: .3125rem;
			padding-left: 0;
			font-size: 0.75rem;
			font-weight: 600;
			width: 100%;
			padding-left: 0.625rem;
			text-align: left;

			&-data {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}

		&_value {
			float: left;
			padding: 0.9375rem;
			font-size: 0.875rem;
			text-align: left;
			padding-left: 0;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			width:96%;
		}
	}
}

.selected-sightseeing-details-container__selected-data{
	width: 25%;
}

.traveller-details-container__selected-data_value{
	padding-left: .625rem;
}
