@use "sass:math";

.sightseeing-contact__header,
.sightseeing-desc__header,
.sightseeing-amenity__header {
	min-height: 28px;
	background-color: $sightseeing-contact-header-bg-color;
	color: $sightseeing-contact-header-text-color;
	text-transform: uppercase;
	padding: 5px;
	font-size: 0.75rem;
	font-weight: 600;
	padding-left:.625rem ;
}

.sightseeing-contact__content {
	ul {
		padding-bottom: .625rem;

		>li {
			padding-top: .3125rem;
			border-bottom: 2px solid $sightseeing-contact-content-bottom-border-color;

			&:last-child {
				border-bottom: none;
			}
		}
	}
}

.sightseeing-contact__content,
.sightseeing-desc__content,
.sightseeing-amenity__content {
	padding-left: 12px;

	&_sightseeing-name {
		color: $sightseeing-contact-content-title-text-color;
		font-size: .75rem;
	}

	&_title {
		color: $sightseeing-contact-content-title-text-color;
		font-size: .75rem;
		text-transform: uppercase;
	}

	&_info {
		color: $sightseeing-contact-content-info-text-color;

		.sightseeing-contact {
			&__details {
				font-weight: bold;
				width: 100%;
				padding-left: 0;
				font-size: 0.8rem;
			}

			&__icon {
				font-size: 0.5rem;
			}
		}
	}

	&_location {
		width: 100%;
	
	}

	&_city {
		color: $sightseeing-contact-content-info-text-color;
	}
}

.sightseeing-desc__content {
	padding-top: .625rem;
}

.sightseeing-amenity__content {
	@include clearfix;

	&_amenity {
		min-width: 25%;
		float: left;
	}
}

.amenity__logo {
	padding-left: 15px;
	padding-right: 5px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.amenity__info {
	padding-right: 5px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.sightseeing-highlights-loader {
	text-align: center;
	padding-top: .625rem;
}

.sightseeing-highlights {
	max-height: calc(100vh - 100px);
	width: 100%;
    overflow:auto;	
	transition: all .5s ease-in-out;
	@include clearfix();
	&__sightseeing-name {
			padding-bottom: .625rem;
			font-size: 1.5rem;
			padding-left: .625rem;
			color: $brand-color-2;
		}

	.highlights {
		&__sidebar {
			float: left;
			width: 100%;
			height: 3.125rem;
			background-color: $body-bg;
		}

		&__sightseeing-details {
			float: right;
			width: 100%;
			height: 100%;
			margin-top: .625rem;
		}
	}

	.sidebar {
		float: left;
		padding-left: 0;
		padding-right: 2rem;
		color: $light;
		text-transform: uppercase;
		position: fixed;
		width: 100%;

		&__sight-seeing-name {
			background-color: $brand-color-2;
			@include clearfix;
			width: 45%;
			padding-bottom: 0.5rem;
			display: flex;
			float: left;
			font-weight: 600;
			text-align: left;
			font-size: $font-size-h2;
			padding-top: 0.5rem;
			padding-left: 0.625rem;

			span {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}

		&__tabs {
			background-color: $brand-color-2;
			@include clearfix;
			width: 55%;
			padding-bottom: 0.66rem;
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
			flex-direction: row;
			flex-wrap: nowrap;
			float: right;
			padding-top: 0.4375rem;

			&_action-button {
				text-align: center;
				@include make-one-fourth;
				padding-left: 0.5rem;
				padding-right: 0.5rem;
				min-height: 1.85rem;
				margin: 2px;
				font-size: 0.85rem;
				background-color: $light;
				border-radius: 0.1875rem;
				color: $brand-color-2;
			}

			.active {
				color: $brand-color-1;
			}
		}
	}

	.sightseeing-details {
		&__sightseeing-info {
			@include clearfix;
			width: 100%;
			padding-bottom: .625rem;
		}

		&__content {
			width: 100%;
			height: 100%;
			&_active {
				opacity: 1 !important;
				transition: all ease-in-out 0.6s !important;
			}

			&_details {
				transition: all 0.5s ease;
				right: 0;
				top: 0;
				bottom: 0;
				opacity: 0;
			}

			.modal__content_sightseeing-desc,
			.modal__content_sightseeing-selection {
				margin-top: 1rem;
			}
		}
	}

	.sightseeing-info {
		&__feature-info-container {
			width: 100%;
			@include clearfix();

			&_feature {
				float: left;
				background: $brand-color-7;
				color: $dark;
				border-radius: 3px;
				padding: 0.3125rem;
				margin-left: 0.625rem;
				margin-bottom: 0.625rem;
				font-weight: 400;
			}
		}

		&__sightseeing-image {
			float: left;
			padding-left: .625rem;
			width: 20%;

			.result__thumbnail {
				width: 100%;
				height: 150px;
			}
		}

		&__sightseeing-description {
			float: left;
			width: 60%;
		}

		&__price-container {
			float: left;
			width: 40%;

			&_title {
				font-weight: 400;
				text-align: left;
				font-size: 1.125rem;
				color: $brand-color-2;
				padding-right: .625rem;
			}

			&_price {
				text-align: left;
				&-currency {
					font-size: .75rem;
				}

				&-value {
					font-size: $font-size-h3;
					font-family: $font-family-number;
					color: $brand-color-1;
				}
				&-adhoc-price {
					color: $brand-color-14;
				}

				&-adhoc {
					display: inline-block;
					text-align: center;
				}
			}
		}

		&__select-option-button-container {
			width: 100%;
			padding: .9375rem 0;
			@include clearfix();

			&_button {
				@include button-size(math.div($base-padding, 6), 0, $font-size-h6, inherit, 0);
				@include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state, 20));
				width: 100%;
				font-weight: 700;
				text-transform: uppercase;
				float: right;
				width: 15%;
			}
		}

		&__book {
			padding-left: 1rem;
			padding-right: 1rem;

			>hr {
				border: 0.1rem solid $gray;
			}

			&_button {
				@include button-size(math.div($base-padding, 6), 0, $font-size-h6, inherit, 0);
				@include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state, 20));
				width: 100%;
				font-weight: 700;
				text-transform: uppercase;
				float: right;
				width: 20%;
			}

			.price {
				&__label {
					font-size: $font-size-h3;
					font-family: $font-family-number;
					color: $brand-color-2;
				}

				&__amount {
					font-size: $font-size-h3;
					font-family: $font-family-number;
					color: $brand-color-1;
				}
			}
		}
	}

	.description__sightseeing {
		padding-left: 10px;
		width: 100%;

		&_name {
			color: $brand-color-2;
			font-weight: 400;
			text-align: left;
			font-size: $font-size-h3;
		}

		&_duration {
			width: 100%;
			@include clearfix();

			&-title {
				float: left;
				font-weight: 600;
				color: $dark;
			}

			&-value {
				float: left;
				color: $brand-color-5;
				font-size: .75rem;
				font-weight: 600;
				padding-left: .3125rem;
			}
		}
	}

	.sightseeing-slideshow {
		background-color: $light;
		width: 100%;
		@include clearfix();

		&__image-list {
			float: left;
			width: 20%;
			@include clearfix();
		}

		&__selected-image {
			float: left;
			width: 80%;
			padding-top: 3rem;
			@include clearfix();

			&_active-image {
				float: left;
				width: 90%;

				>img {
					width: 80%;
					height: calc(100vh - 180px);
					display: block;
					margin: 0 auto;
				}
			}

			&_slideshow-arrow-prev,
			&_slideshow-arrow-next {
				float: left;
				font-size: 4rem;
				margin-top: 18%;
			}
		}

	}
}

.sightseeing-details-modal-container {
	@include clearfix();
	width: 100%;

	&__close-button {
		width: 25%;
		float: right;
		text-align: right;
		cursor: pointer;
		font-size: 1.125rem;
		padding-bottom: $base-padding*0.25;
		color: $brand-color-2;
		text-transform: uppercase;

		&_icon {
			padding-right: .625rem;
		}
	}

	.modal__content {
		max-height: 100% !important;
	}

	&__sightseeing-highlight-loader {
		margin-top: 20%;
		text-align: center;
		font-size: 1rem;
		color: $brand-color-2;
		&_message{
			margin-top: 1rem;
		}
	}
}

.sightseeing-review-container,
.sightseeing-questions-ans-container {
	width: 100%;
	@include clearfix();

	&__review,
	&__data {
		border: 1px solid $brand-color-34;
		width: 45%;
		padding: .625rem;
		border-radius: .3125rem;
		float: left;
		margin-left: 1rem;
		margin-top: 1rem;

		&_title {
			font-size: 1rem;
			text-transform: capitalize;
			color: $dark;
		}

		&_value,
		&_star-rating {
			font-size: .75rem;
			padding-top: .3125rem;
			padding-left: 0;

		}
		&_star-rating {
			color: $brand-color-2;
		}
	}
}

.sightseeing-question-ans-data-container{
	margin-top:1rem;
}

	.map-view {
		&__wrapper {
		    margin-top: .625rem;
		    margin-bottom: .9375rem;
		    position: relative;
		  	.map{
		  		height: 100%;
		  		&__wrapper {
			   		height: 500px;
			   	 	width: 100%;
			  	}
			}
		}
	}
	
	.price-tag {
  background-color: $brand-color-2;
  border-radius: 8px;
  color: $light;
  font-size: 0.75rem;
  padding: 0.5rem 0.3125rem;
  position: relative;
}

.price-tag::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: .5rem solid transparent;
  border-right: .5rem solid transparent;
  border-top: .5rem solid $brand-color-2;
}
.sightseeing-description-details-container,
.sightseeing-inclusion-exclusion-container,
.sightseeing-address-container,
.sightseeing-slideshow-container,
.sightseeing-reviews-container,
 .sightseeing-select-option-container {
	padding-top: 3rem;
}